import _debounce from "lodash/debounce";
import common from '@mixins/common';
import filters from '@filters/filters';
import { formatPx2Rem } from '@utils/index';
import { isEmpty, dateFilter } from '@utils/utils';
import { mapGetters } from 'vuex';
import { updateBookAPI, addBookAPI } from '@api/appointment';
export default {
  name: 'AppointmentApply',
  mixins: [common, filters],
  components: {},
  data() {
    return {
      headerTitle: '接待登记',
      isLoading: false,
      // 是否在加载数据
      isRefreshPage: false,
      // 是否需要刷新页面
      noDataMessage: '',
      // 列表为空时的提示文案
      detailInfo: {},
      // 详情信息
      show1: false,
      minDate1: new Date(),
      maxDate1: new Date(2099, 12, 31),
      currentDate1: new Date(),
      show2: false,
      sourceList: '同城58,安居客,中介,贝壳,公司协议客户,自然来访,小猪短租,爱彼迎,途家,美团民宿,老客户介绍,品牌公寓,携程,木鸟民宿,美团酒店,去哪儿网,艺龙酒店,飞猪,途牛旅游网'.split(','),
      dateIconUrl: require('../../assets/images/date.png'),
      bookerName: '',
      bookerSex: '',
      bookerPhone: '',
      bookerProfession: '',
      expectLayouts: '',
      source: '',
      remark: '',
      bookVisitTime: ''
    };
  },
  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  methods: {
    formatPx2Rem,
    // 日期格式化
    dateFilter,
    // 空字段过滤
    fieldEmptyFilter(data, returnEmpty = '无') {
      return !isEmpty(data) ? data : returnEmpty;
    },
    // 字母数字过滤
    formatterField(value) {
      return value.replace(/[^\u4E00-\u9FA5]/g, '');
    },
    // 只能输入数字字母
    formatterField2(value) {
      return value.replace(/[^\w\.\/]/ig, '');
    },
    hourFilter(type, options) {
      if (type === 'hour') {
        // 验房时间是早9晚6
        return options.filter(option => ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'].includes(option));
      }
      return options;
    },
    onConfirmSource(val) {
      this.source = val;
      this.show2 = false;
    },
    handleApply: _debounce(function () {
      if (!this.bookerName) {
        this.$toast('请输入姓名');
        return;
      }
      if (!this.id) {
        if (!this.bookerPhone) {
          this.$toast('请输入手机号码');
          return;
        }
        if (!this.source) {
          this.$toast('请选择来源渠道');
          return;
        }
      }
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认提交？',
        confirmButtonColor: '#D8B084'
      }).then(async () => {
        // on confirm
        await this.submitApplyData();
      }).catch(() => {
        // on cancel
      });
    }, 200),
    async submitApplyData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 完成接待
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let response;
        if (this.id) {
          response = await updateBookAPI({
            id: this.id,
            remark: this.remark,
            bookerSex: this.bookerSex,
            bookerName: this.bookerName,
            expectLayouts: this.expectLayouts,
            bookerProfession: this.bookerProfession
          });
        } else {
          response = await addBookAPI({
            projectId: this.projectInfo.projectId,
            bookerName: this.bookerName,
            bookerSex: this.bookerSex,
            bookerPhone: this.bookerPhone,
            expectLayouts: this.expectLayouts,
            remark: this.remark,
            source: this.source,
            bookerProfession: this.bookerProfession
          });
        }
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          // 接口请求处理成功之后回跳
          if (this.id) {
            this.$store.dispatch('appointment/UpdateAppointmentDetail', {
              id: this.id,
              remark: this.remark,
              bookerSex: this.bookerSex,
              bookerName: this.bookerName,
              expectLayouts: this.expectLayouts,
              bookerProfession: this.bookerProfession
            });
          }
          this.$toast({
            message: '提交成功！',
            onClose: () => {
              this.$router.go(-1);
            }
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async handleCallback({
      isError,
      errorMessage = undefined
    }) {
      // 回调处理
      if (isError) {}
    },
    async getDetailData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 获取详情数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          id
        } = this;
        let response = await this.$store.dispatch('appointment/GetAppointmentDetail', {
          id
        });
        // let result = response.data
        let result = response;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let resultData = result.data || {};
          this.bookerName = resultData.bookerName || '';
          this.bookerSex = resultData.bookerSex || '';
          this.bookerPhone = resultData.bookerPhone || '';
          this.bookerProfession = resultData.bookerProfession || '';
          this.expectLayouts = resultData.expectLayouts || '';
          this.source = resultData.source || '';
          this.remark = resultData.remark || '';
        }
      } catch (error) {} finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.getDetailData();
    }
  },
  filters: {},
  watch: {},
  created() {},
  mounted() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};